<template>
    <a-drawer
        :width="500"
        @close="onClose"
        :visible="editDrawer.visible"
        :wrapStyle="{overflow: 'auto'}"
        :zIndex="1000"
        :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
        >
        <bhLoading :show="loading" />
        <div class="h-full dF fC jSB">
            <div class="f1" v-if="editDrawer.object.type == 'opportunity'">
                <h5>Edit Opportunity</h5>
                <a-form-model class="mt-5" ref="newOpportunity" :model="newOpportunity">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Deal Name" prop="name" required :rules="req('Please enter a name')">
                                <a-input size="large" v-model="newOpportunity.name" placeholder="Deal Name" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input disabled size="large" :default-value="defaultName" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Sales Owner(s)" prop="owners" required :rules="req('Please select a user')">
                                <a-select
                                    v-model="newOpportunity.owners"
                                    mode="multiple"
                                    placeholder="Add Sales Owner(s)"
                                    size="large"
                                    :filter-option="filterOption"
                                    :not-found-content="null"
                                    @search="searchAssign">
                                    <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                        {{ user.firstName + ' ' + user.lastName }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Estimated Opportunity Close Date</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        Select the estimated close date of the opportunity, not when the home will close
                                    </template>
                                    <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                                </a-tooltip>
                            </div>
                            <a-form-model-item prop="closeDate" required :rules="req('Please enter a Close Date')">
                                <a-date-picker v-model="newOpportunity.closeDate" size="large" placeholder="dd/mm/yyyy" style="width: 100%;" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Deal Stage</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        Select the Deal Stage
                                    </template>
                                    <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                                </a-tooltip>
                            </div>
                            <a-form-model-item prop="stage" required :rules="req('Please select a Deal Stage')">
                                <a-select v-model="newOpportunity.stage" style="width: 100%;" size="large">
                                    <div slot="dropdownRender" slot-scope="menu">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 4px 0;" />
                                        <div
                                            style="padding: 8px; cursor: pointer; color:var(--orange)"
                                            @mousedown="e => e.preventDefault()"
                                            @click="addNewStage"
                                        >
                                            <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Stage
                                        </div>
                                    </div>
                                    <a-select-option v-for="(stage,stageI) in settings.options.stages.list" :key="stage+stageI" :value="stage.id">
                                        {{stage.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Value" prop="dealValue">
                                <a-input v-model="newOpportunity.dealValue" size="large" style="width: 100%;" placeholder="Add deal value" type="number" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Deal Source" prop="dealSource">
                                <a-select v-model="newOpportunity.dealSource" style="width: 100%;" size="large">
                                    <div slot="dropdownRender" slot-scope="menu">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 4px 0;" />
                                        <div
                                            style="padding: 8px; cursor: pointer; color:var(--orange)"
                                            @mousedown="e => e.preventDefault()"
                                            @click="addNewSource"
                                        >
                                            <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Source
                                        </div>
                                    </div>
                                    <a-select-option v-for="(source,sourceI) in dealSources" :key="source+sourceI" :value="source.id ? source.id : source.value">
                                        {{source.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Probability" prop="probability">
                                <a-input v-model="newOpportunity.probability" size="large" addon-after="%" type="number" style="width: 100%;" min="0" max="100" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Visibility" prop="visibility">
                                <a-select @change="changeVisibility" v-model="newOpportunity.visibility" style="width: 100%;" size="large">
                                    <a-select-option value="everyone">
                                        Everyone
                                    </a-select-option>
                                    <a-select-option value="owners">
                                        Only Sales Owner(s)
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" class="mb-5">
                            <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="mr-3"><div>Interested In</div></div>
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        Add description of opportunity
                                    </template>
                                    <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                                </a-tooltip>
                            </div>
                            <a-form-model-item prop="interested">
                                <a-textarea
                                    v-model="newOpportunity.interested"
                                    :auto-size="{ minRows: 4, maxRows: 6 }"
                                    placeholder="Add description of opportunity"
                                    />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div class="f1" v-if="editDrawer.object.type == 'note'">
                <h5>Edit Note</h5>
                <a-form-model class="mt-5" ref="newNote" :model="newNote">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Subject" prop="subject" required :rules="req('Please enter a subject')">
                                <a-input size="large" v-model="newNote.subject" placeholder="Subject" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Content" prop="content">
                                <a-textarea size="large" v-model="newNote.content" placeholder="Add note" :auto-size="{ minRows: 6, maxRows: 6 }" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input disabled size="large" :default-value="defaultName" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div class="f1" v-if="editDrawer.object.type == 'task'">
                <h5>Edit Task</h5>
                <a-form-model class="mt-5" ref="newTask" :model="newTask">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Title" prop="title" required :rules="req('Please enter a title')">
                                <a-input size="large" v-model="newTask.title" placeholder="Title" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Description" prop="description" >
                                <a-textarea
                                    placeholder="Task description goes here...."
                                    :autoSize="{ minRows: 2, maxRows: 6 }"
                                    v-model="newTask.description"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Task Type" prop="type" required :rules="req('Please select a type')">
                                <a-select size="large" v-model="newTask.type">
                                    <div slot="dropdownRender" slot-scope="menu">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 4px 0;" />
                                        <div
                                            style="padding: 8px; cursor: pointer; color:var(--orange)"
                                            @mousedown="e => e.preventDefault()"
                                            @click="addNewTaskType"
                                        >
                                            <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Task Type
                                        </div>
                                    </div>
                                    <a-select-option v-for="t in taskTypes" :key="t.id ? t.id:t.value">{{t.name}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Priority Level" required>
                                <div class="dF">
                                    <div @click="selectPriority('high')" :class="newTask.priority && newTask.priority =='high' ? 'select-high':'high-priority'">HIGH PRIORITY</div>
                                    <div @click="selectPriority('medium')" :class="newTask.priority && newTask.priority =='medium' ? 'select-med':'med-priority'">MED PRIORITY</div>
                                    <div @click="selectPriority('low')" :class="newTask.priority && newTask.priority =='low' ? 'select-low':'low-priority'">LOW PRIORITY</div>
                                </div>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item prop="assignTo" label="Assign To" :rules="req('Please assign this task to a user')">
                                <a-select
                                    v-model="newTask.assignTo"
                                    mode="multiple"
                                    placeholder="Type to search..."
                                    size="large"
                                    :filter-option="filterOption"
                                    :not-found-content="null"
                                    @search="searchAssign">
                                    <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                        {{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>{{newTask.contact ? 'Primary Contact' : 'Related To'}}</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input disabled size="large" :default-value="defaultName" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Due Date" prop="dueDate">
                                <a-date-picker size="large" v-model="dateHolder" @change="(value, dateString) => onChange(value, dateString, 'task')" style="width:100%" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div class="f1" v-if="editDrawer.object.type == 'appointment'">
                <h5>Edit Appointment</h5>
                <a-form-model class="mt-5" ref="newAppointment" :model="newAppointment">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Title" prop="title" required :rules="req('Please enter a title')">
                                <a-input size="large" v-model="newAppointment.title" placeholder="Title" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Date" prop="date" required :rules="req('Please pick a date')">
                                <a-date-picker style="width:100%" size="large" v-model="dateHolder" @change="(value, dateString) => onChange(value, dateString, 'appointment')" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Start Time" prop="startTime" required :rules="req('Please pick a start time')">
                                <a-time-picker v-model="startTimeHolder" @change="(value) => changeTime(value, 'startTime')" style="width:100%" format="HH:mm" size="large" placeholder="Select time" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="End Time" prop="endTime" required :rules="req('Please pick an end time')">
                                <a-time-picker v-model="endTimeHolder" @change="(value) => changeTime(value, 'endTime')" style="width:100%" format="HH:mm" size="large" placeholder="Select time" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>{{newAppointment.contact ? 'Primary Contact' : 'Related To'}}</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input disabled size="large" :default-value="defaultName" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Appointment Type" required :rules="req('Please select one')" prop="type">
                                <a-select size="large" v-model="newAppointment.type" placeholder="Select a type">
                                    <a-select-option v-for="t in appointmentTypes" :key="t.id ? t.id:t.value" :value="t.id ? t.id:t.value">{{t.name}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item :label="newAppointment.type === 'phonemeeting' ? 'Phone Number' : 'Location'" prop="location">
                                <a-input v-model="newAppointment.location" size="large" :placeholder="newAppointment.type === 'phonemeeting' ? 'Enter Phone Number' : 'Enter location'"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Assign To" prop="assignTo" :rules="req('Please select a user')">
                                <a-select
                                    v-model="newAppointment.assignTo"
                                    mode="multiple"
                                    :show-arrow="false"
                                    placeholder="Type to search..."
                                    :not-found-content="null"
                                    size="large"
                                    :filter-option="filterOption"
                                    @search="searchAssign">
                                    <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                        {{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" class="mb-3">
                            <a-checkbox v-model="reminder">Setup a reminder</a-checkbox>
                        </a-col>
                        <a-col v-if="reminder" :span="24">
                            <a-form-model-item prop="reminderType">
                                <a-select size="large" v-model="newAppointment.reminderType">
                                    <a-select-option value="r15m">15 Minutes</a-select-option>
                                    <a-select-option value="r30m">30 Minutes</a-select-option>
                                    <a-select-option value="r1h">1 Hour</a-select-option>
                                    <a-select-option value="r2h">2 Hours</a-select-option>
                                    <a-select-option value="r6h">6 Hours</a-select-option>
                                    <a-select-option value="r12h">12 Hours</a-select-option>
                                    <a-select-option value="r1d">1 Day</a-select-option>
                                    <a-select-option value="r2d">2 Days</a-select-option>
                                    <a-select-option value="r1w">1 Week</a-select-option>
                                    <a-select-option value="onetime">One-time</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="Notes" prop="notes">
                                <a-textarea v-model="newAppointment.notes" :auto-size="{ minRows: 3, maxRows: 3 }"></a-textarea>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
                <div class="dF jSB">
                    <a-button class="no-padding-button delete-button" @click="delObj" style="width:125px" size="large"><a-icon type="delete" />DELETE</a-button>
                    <div class="dF ml-3">
                        <a-button @click="onClose" class="no-padding-button mr-3 cancel-button" size="large" style="width:125px; background-color:#ECE9F1; color:#3F3356">CANCEL</a-button>
                        <a-button class="no-padding-button" @click="submit" style="width:125px" size="large" type="primary">UPDATE</a-button>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import moment from 'moment'
import bhLoading from 'bh-mod/components/common/Loading'
import Index from '../layout/SubBar/index.vue'
// import Index from '../layout/SubBar/index.vue'
export default {
    components:{
        bhLoading,Index,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return{
            newOpportunity:{},
            newNote:{},
            newTask:{},
            newAppointment:{},
            defaultName:'',
            userSource:[],
            loading:false,
            dateHolder:'',
            startTimeHolder:'',
            endTimeHolder:'',
            reminder:false,
            noteId:""
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (val) {
                    if (this.editDrawer.object.type == 'opportunity' && this.editDrawer.object.id && this.editDrawer.object.type) {
                        let index = this.opportunities.findIndex(x => x.id == this.editDrawer.object.id)
                        if (index != -1) this.newOpportunity = JSON.parse(JSON.stringify(this.opportunities[index]))
                        let id = this.newOpportunity.contact
                        if (id.id) id = id.id
                        if (this.$store.state.contacts.allContacts[id] && this.$store.state.contacts.allContacts) {
                            let find = this.$store.state.contacts.allContacts[id]
                            this.defaultName = `${find.firstName} ${find.lastName}`
                        }
                        if (this.newOpportunity.owners && this.newOpportunity.owners.length) {
                            this.newOpportunity.owners = this.newOpportunity.owners.map(x => x = x.id)
                            this.newOpportunity.owners.forEach(id => {
                                let index = this.users.findIndex(x => x.id == id)
                                if (index != -1) {
                                    this.userSource.push(this.users[index])
                                }
                            })
                        }
                        this.newOpportunity.closeDate = this.moment(new Date(this.newOpportunity.closeDate))
                    } else if (this.editDrawer.object.type == 'note' && this.editDrawer.object.id && this.editDrawer.object.type) {
                        let index = Object.values(this.allContacts).findIndex(x => {
                            if (x.notes && x.notes.length) {
                                if (typeof x.notes[0] == 'object') {
                                    let ids = x.notes.map(x => x = x.id)
                                    if (ids.includes(this.editDrawer.object.id)) return x
                                }
                            }
                        })
                        if (index != -1) {
                            let notes = Object.values(this.allContacts)[index].notes

                            if (typeof notes[0] == 'object') {
                                let find = notes.find(x => x.id == this.editDrawer.object.id)
                                if (find) this.newNote = JSON.parse(JSON.stringify(find))
                            }
                            this.noteId = Object.values(this.allContacts)[index].id
                            this.defaultName = `${Object.values(this.allContacts)[index].firstName} ${Object.values(this.allContacts)[index].lastName}`
                        } else {
                            let oppIndex = this.opportunities.findIndex(x => {
                                if (x.notes && x.notes.length) {
                                    if (typeof x.notes[0] == 'object') {
                                        let ids = x.notes.map(x => x = x.id)
                                        if (ids.includes(this.editDrawer.object.id)) return x
                                    }
                                }
                            })
                            if (oppIndex != -1) {
                                let notes = this.opportunities[oppIndex].notes
                                if (typeof notes[0] == 'object') {
                                    let find = notes.find(x => x.id == this.editDrawer.object.id)
                                    if (find) this.newNote = JSON.parse(JSON.stringify(find))
                                }
                                this.noteId = this.opportunities[oppIndex].id
                                this.defaultName = `${this.opportunities[oppIndex].name}`
                            } else {
                                let index = Object.values(this.externalContacts).findIndex(x => {
                                    if (x.notes && x.notes.length) {
                                        if (typeof x.notes[0] == 'object') {
                                            let ids = x.notes.map(x => x = x.id)
                                            if (ids.includes(this.editDrawer.object.id)) return x
                                        }
                                    }
                                })
                                if (index != -1) {
                                    let notes = Object.values(this.externalContacts)[index].notes

                                    if (typeof notes[0] == 'object') {
                                        let find = notes.find(x => x.id == this.editDrawer.object.id)
                                        if (find) this.newNote = JSON.parse(JSON.stringify(find))
                                    }
                                    this.noteId = Object.values(this.externalContacts)[index].id
                                    this.defaultName = `${Object.values(this.externalContacts)[index].firstName} ${Object.values(this.externalContacts)[index].lastName}`
                                }
                            }
                        }
                    } else if (this.editDrawer.object.type == 'task' && this.editDrawer.object.id && this.editDrawer.object.type) {
                        if (this.$store.state.contacts.tasks && this.$store.state.contacts.tasks.length) {
                            let storeTasks = this.$store.state.contacts.tasks
                            let find = storeTasks.find(x => x.id == this.editDrawer.object.id)
                            if (find) this.newTask = JSON.parse(JSON.stringify(find))
                        }
                        if (this.newTask.assignTo && this.newTask.assignTo.length) {
                            this.newTask.assignTo = this.newTask.assignTo.map(x => x = x.id)
                            this.newTask.assignTo.forEach(id => {
                                let index = this.users.findIndex(x => x.id == id)
                                if (index != -1) {
                                    this.userSource.push(this.users[index])
                                }
                            })
                        }
                        if (this.newTask.type && this.newTask.type != '') {
                            let ids = this.taskTypes.map(x => x = x.id)
                            if (!ids.includes(this.newTask.type)) this.newTask.type = ''
                        }
                        let id = ''
                        if (this.newTask.contact) {
                            id = this.newTask.contact
                            if (id.id) id = id.id
                        } else if (this.newTask.opportunity) {
                            id = this.newTask.opportunity
                            if (id.id) id = id.id
                        }
                        if (this.newTask.contact && this.$store.state.contacts.allContacts[id] && this.$store.state.contacts.allContacts) {
                            let find = this.$store.state.contacts.allContacts[id]
                            this.defaultName = `${find.firstName} ${find.lastName}`
                        } else if (this.newTask.opportunity) {
                            let find = this.$store.state.contacts.opportunities.find(x => x.id == id)
                            if (find) {
                                this.defaultName = `${find.name}`
                            }
                        }
                        if (this.newTask.dueDate && this.newTask.dueDate != 0) this.dateHolder = moment(new Date(this.newTask.dueDate))
                    } else if (this.editDrawer.object.type == 'appointment' && this.editDrawer.object.id && this.editDrawer.object.type) {
                        if (this.$store.state.contacts.appointments && this.$store.state.contacts.appointments.length) {
                            let storeApp = this.$store.state.contacts.appointments
                            let find = storeApp.find(x => x.id == this.editDrawer.object.id)
                            if (find) this.newAppointment = JSON.parse(JSON.stringify(find))
                            if (this.newAppointment.date) this.dateHolder = moment(new Date(this.newAppointment.date))
                            if (this.newAppointment.startTime) this.startTimeHolder = moment(new Date(this.newAppointment.startTime))
                            if (this.newAppointment.endTime) this.endTimeHolder = moment(new Date(this.newAppointment.endTime))
                            if (this.newAppointment.type && this.newAppointment.type != '') {
                                let ids = this.appointmentTypes.map(x => x = x.id)
                                if (!ids.includes(this.newAppointment.type)) this.newAppointment.type = ''
                            }
                            if (this.newAppointment.contact) {
                                let id = this.newAppointment.contact
                                if (id.id) id = id.id
                                if (this.$store.state.contacts.allContacts[id] && this.$store.state.contacts.allContacts) {
                                    let find = this.$store.state.contacts.allContacts[id]
                                    if (find && find.firstName && find.lastName) this.defaultName = `${find.firstName} ${find.lastName}`
                                } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[id]) {
                                    let find = this.$store.state.contacts.externalContacts[id]
                                    if (find && find.firstName && find.lastName) this.defaultName = `${find.firstName} ${find.lastName}`
                                }
                            } else if (this.newAppointment.opportunity) {
                                let id = this.newAppointment.opportunity
                                if (id.id) id = id.id
                                let find = this.$store.state.contacts.opportunities.find(x => x.id == id)
                                if (find) this.defaultName = `${find.name}`
                            }
                            if (this.newAppointment.assignTo && this.newAppointment.assignTo.length) {
                                this.newAppointment.assignTo = this.newAppointment.assignTo.map(x => x = x.id)
                                this.newAppointment.assignTo.forEach(id => {
                                    let index = this.users.findIndex(x => x.id == id)
                                    if (index != -1) {
                                        this.userSource.push(this.users[index])
                                    }
                                })
                            }
                            if (!this.newAppointment.reminderType || this.newAppointment.reminderType == 'none') this.newAppointment.reminderType = 'r15m'
                            else this.reminder = true
                        }
                    }
                } else {
                    this.defaultName = ''
                    this.userSource = []
                    this.dateHolder = ''
                    this.startTimeHolder = ''
                    this.endTimeHolder = ''
                    this.reminder = false
                }
            }
        }
    },
    computed:{
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        dealSources() {
            if (this.settings && this.settings.options && this.settings.options.dealSources && this.settings.options.dealSources.length > 0) {
                return this.settings.options.dealSources
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
        settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
        users() {
            return this.$store.state.contacts.users
        },
        visible() {
            return this.$store.state.contacts.editDrawer.visible
        },
        editDrawer() {
            return this.$store.state.contacts.editDrawer
        },
        opportunities() {
            return this.$store.state.contacts.opportunities
        },
        owner() {
            return this.$store.state.user.user
        },
        allContacts() {
            return this.$store.state.contacts.allContacts || {}
        },
        taskTypes() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
                return this.$store.state.contacts.allSettings.app.options.taskType
            } else {
                return [
                    {name:'To-do', id:'todo'},
                    {name:'Call', id:'call'},
                    {name:'Follow up', id:'followup'}
                ]
            }
        },
        appointmentTypes() {
            return [
				{ name: 'Virtual Meeting', id: 'virtualmeeting' },
				{ name: 'Phone Meeting', id: 'phonemeeting' },
				{ name: 'Sales Center', id: 'salescentre' }
			]
        },
    },
    methods:{
        addNewTaskType() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'taskType'})
        },
        addNewStage() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealStage'})
        },
        addNewSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealSource'})
        },
        addNewType() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'appointmentType'})
        },
        delObj() {
            if (this.editDrawer.object.type == 'opportunity') {
                this.deleteOpportunity()
            } else if (this.editDrawer.object.type == 'note') {
                this.deleteNote()
            } else if (this.editDrawer.object.type == 'task') {
                this.deleteTask()
            } else if (this.editDrawer.object.type == 'appointment') {
                this.deleteAppointment()
            }
        },
        submit() {
            if (this.editDrawer.object.type == 'opportunity') {
                this.updateOpportunity()
            } else if (this.editDrawer.object.type == 'note') {
                this.updateNote()
            } else if (this.editDrawer.object.type == 'task') {
                this.updateTask()
            } else if (this.editDrawer.object.type == 'appointment') {
                this.updateAppointment()
            }
        },
        deleteAppointment() {
            if (this.editDrawer.object.id && this.editDrawer.object.id != '') {
                let self = this
                if (this.$p < 40 && (!this.editDrawer.object.createdBy || this.editDrawer.object.createdBy.id !== this.$store.state.user.user.id)){
                    return this.$message.error('You do not have permission to delete Appointments')
                }
                this.$confirm({
                    title: "Delete Appointment",
                    content: h => <div>Do you want to delete this appointment?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/appointments/:instance/${self.editDrawer.object.id}`).then( ({data}) => {
                            self.loading = false
                            self.$store.commit('DELETE_APPOINTMENT', data)
                            self.onClose()
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        deleteTask() {
            if (this.editDrawer.object.id && this.editDrawer.object.id != '') {
                let self = this
                if (this.$p < 40 && (!this.editDrawer.object.createdBy || this.editDrawer.object.createdBy.id !== this.$store.state.user.user.id)){
                    return this.$message.error('You do not have permission to delete Tasks')
                }
                this.$confirm({
                    title: "Delete Task",
                    content: h => <div>Do you want to delete this task?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/tasks/:instance/${self.editDrawer.object.id}`).then( ({data}) => {
                            self.loading = false
                            self.$store.commit('DELETE_TASK', data)
                            self.onClose()
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        deleteNote() {
            if (this.editDrawer.object.id && this.editDrawer.object.id != '' && this.noteId != '') {
                let self = this
                this.$confirm({
                    title: "Delete Note",
                    content: h => <div>Do you want to delete this Note?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/contacts/:instance/${self.noteId}/${self.editDrawer.object.id}/note`).then( ({data}) => {
                            self.$store.commit('DELETE_NOTE', self.editDrawer.object)
                            self.loading = false
                            self.onClose()
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else {
                return this.$message.error("Something went wrong")
            }
        },
        deleteOpportunity() {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Opportunities')
            }
            let index = this.opportunities.findIndex(x => x.id == this.editDrawer.object.id)
            let name = ''
            if (index != -1) name = this.opportunities[index].name
            let self = this
            this.$confirm({
                title: "Delete Opportunity",
                content: h => <div>Do you want to delete <b>{name}</b>? All information related to them will be lost.</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
                    self.$api.delete(`/opportunities/:instance/${self.editDrawer.object.id}`).then( ({data}) => {
                        self.$store.commit('UPDATE_OPPORTUNITIES', data)
                        self.loading = false
                        self.onClose()
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        updateAppointment() {
            this.$refs.newAppointment.validate(valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.newAppointment))
                    if (sendObj.contact && typeof sendObj.contact == 'object') sendObj.contact = sendObj.contact.id
                    if (!this.reminder) sendObj.reminderType = 'none'
                    this.loading = true
                    this.$api.put(`/appointments/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_APPOINTMENT', data)
                        this.$emit('update')
                        this.loading = false
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        updateTask() {
            this.$refs.newTask.validate(valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.newTask))
                    if (sendObj.contact && typeof sendObj.contact == 'object') sendObj.contact = sendObj.contact.id
                    this.loading = true
                    this.$api.put(`/tasks/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_TASK', data)
                        this.$emit('update')
                        this.loading = false
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        updateNote() {
            this.$refs.newNote.validate(valid => {
                if (valid) {
                    let note = JSON.parse(JSON.stringify(this.newNote))
                    let sendObj = {
                        subject: note.subject,
                        content: note.content
                    }
                    this.loading = true
                    this.$api.put(`/comments/:instance/${note.id}`,sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_NOTE',data)
                        this.$emit('update')
                        this.loading = false
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        updateOpportunity() {
            this.$refs.newOpportunity.validate(valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.newOpportunity))
                    sendObj.closeDate = parseInt(this.moment(sendObj.closeDate).format('X'))*1000
                    if (sendObj.contact && sendObj.contact.id) sendObj.contact = sendObj.contact.id
                    this.loading = true
                    this.$api.put(`/opportunities/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_OPPORTUNITY', data)
                        this.$emit('update')
                        this.loading = false
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        changeTime (value, type) {
            if (type == 'startTime') {
                this.newAppointment.startTime = parseInt(value.format('X'))*1000
            } else if (type == 'endTime') {
                this.newAppointment.endTime = parseInt(value.format('X'))*1000
            }
        },
        onChange(value, dateString, type){
            if (type == 'task') {
                this.newTask.dueDate = parseInt(this.moment(dateString).format('X'))*1000
            } else if (type == 'appointment') {
                this.newAppointment.date = parseInt(this.moment(dateString).format('X'))*1000
            }
        },
        changeVisibility(e) {
            if (this.editDrawer.object.type == 'opportunity') {
                if (e == 'owners') {
                    // this.newOpportunity.owners = this.users.map(x => x = x.id)
                    let ids = this.userSource.map(x => x = x.id)
                    this.users.forEach(user => {
                        user.name = `${user.firstName} ${user.lastName}`
                        if (!ids.includes(user.id)) this.userSource.push(user)
                    })
                } else if (e == 'everyone') {
                    // this.newOpportunity.owners = [this.owner.id]
                    let ids = this.userSource.map(x => x = x.id)
                    this.users.forEach(user => {
                        user.name = `${user.firstName} ${user.lastName}`
                        if (!ids.includes(user.id)) this.userSource.push(user)
                    })
                }
            }
        },
        selectPriority(item){
            this.newTask.priority = item
        },
        moment,
        req:msg=>({required:true,message:msg}),
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        onClose() {
            if (this.editDrawer.object.type == 'task' && this.$refs.newTask) {
                this.$refs.newTask.resetFields()
            } else if (this.editDrawer.object.type == 'appointment' && this.$refs.newAppointment) {
                this.$refs.newAppointment.resetFields()
            } else if (this.editDrawer.object.type == 'note' && this.$refs.newNote) {
                this.$refs.newNote.resetFields();
            } else if (this.editDrawer.object.type == 'opportunity' && this.$refs.newOpportunity) {
                this.$refs.newOpportunity.resetFields();
            }
            this.$store.commit('CLOSE_EDIT_DRAWER')
        }
    }
}
</script>

<style>
.delete-button:hover{
    background-color:#FD647C;
    color:#FFF !important;
}
.delete-button{
    border-color:#FD647C;
    color:#FD647C;
}
.cancel-button.ant-btn:hover {
    border-color:#ECE9F1 !important;
}
.no-padding-button.ant-btn-lg{
    padding-right:0 !important;
    padding-left: 0 !important;
}
</style>
<style scoped>
.select-high{
    cursor:pointer;
    background-color:var(--danger);
    color:white;
    border:1px solid var(--danger);
    margin-right:10px;
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.select-med{
    cursor:pointer;
    background-color:var(--orange);
    color:white;
    border:1px solid var(--orange);
    margin-right:10px;
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.select-low{
    cursor:pointer;
    background-color:var(--teal);
    color:white;
    border:1px solid var(--teal);
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.high-priority {
    cursor:pointer;
    color:var(--danger);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
    margin-right:10px;
}
.high-priority:hover{
    cursor:pointer;
    background-color:var(--danger);
    border:1px solid var(--danger);
    color:white;
    margin-right:10px;
}
.med-priority {
    cursor:pointer;
    color:var(--orange);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
    margin-right:10px;
}
.med-priority:hover{
    cursor:pointer;
    background-color:var(--orange);
    border:1px solid var(--orange);
    color:white;
    margin-right:10px;
}
.low-priority {
    cursor:pointer;
    color:var(--teal);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
}
.low-priority:hover{
    cursor:pointer;
    background-color:var(--teal);
    border:1px solid var(--teal);
    color:white;
}
</style>
